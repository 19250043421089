.my-checkbox{
    transition: all ease 0.2s;
}
.card {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
@media only screen and (max-width: 600px) {
    .footer {
      display: none !important; /* Add !important to ensure the style takes precedence */
    }
  }